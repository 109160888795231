"use client";
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";
import { toast } from "react-toastify";
import GenericModal from "../modals/GenericModal";
import { AnimatePresence } from "framer-motion";
const url = process.env.NEXT_PUBLIC_SERVER_BASE_URL;
import { motion } from "framer-motion";
import { Context, Store } from "@context/store";
import { useAxiosPrivate } from "@api/axios";
import ToggleWishList from "./ToggleWishList";
import ImageComponent from "next/image";
const Card = ({ property, previewImages }) => {
  const [showShare, setShowShare] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [, setPreloadedImages] = useState([]);
  const { propertyName, _id, area, city } = property;
  const axios = useAxiosPrivate();
  // Function to preload images
  const preloadImages = (images) => {
    const loadedImages = images.map((image) => {
      const img = new Image();
      img.src = image || "/img.png";
      return img;
    });
    setPreloadedImages(loadedImages);
  };

  useEffect(() => {
    // Preload images on component mount
    if (previewImages && previewImages.length > 0) {
      preloadImages(previewImages);
    }
  }, [previewImages]);

  const nextImage = () => {
    setPreviewIndex((prev) =>
      prev === previewImages.length - 1 ? 0 : prev + 1
    );
  };

  const prevImage = () => {
    setPreviewIndex((prev) =>
      prev === 0 ? previewImages.length - 1 : prev - 1
    );
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`${url}/Hotels/${_id}`).then(() => {
      toast.success(`Link copied to the clipboard`);
    });
  };

  const handleShareHotel = () => {
    const propertyLink = `${url}/Hotel/${_id}`;

    if (navigator.share) {
      navigator
        .share({
          title: `Check out this property: ${propertyName}`,
          url: propertyLink,
          text: "I found this amazing property!",
        })
        .catch((err) => {
          console.error("Failed to share:", err);
        });
    } else {
      navigator.clipboard
        .writeText(propertyLink)
        .then(() => {
          alert("Link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  return (
    <>
      <AnimatePresence>
        {showShare && (
          <GenericModal onClose={setShowShare}>
            <div className="share-modal">
              <h4>Share the property</h4>
              <div className="property">
                <ImageComponent
                  src={previewImages?.[0] || "/img.png"}
                  alt="property"
                  width={200}
                  height={200}
                />
                <h3>{propertyName}</h3>
              </div>

              <div className="share-buttons">
                <button className="cancel-button" onClick={handleCopy}>
                  Copy Link
                </button>
                <button className="cancel-button" onClick={handleShareHotel}>
                  Share
                </button>
              </div>
            </div>
          </GenericModal>
        )}
      </AnimatePresence>
      <div className="card">
        <div className="card-image">
          <motion.div
            key={previewImages?.[previewIndex]}
            initial={{ filter: "brightness(50%)" }}
            animate={{
              filter: "brightness(100%)",
              transition: { duration: 0.5, ease: "easeInOut" },
            }}
            style={{ height: "100%" }}
          >
            <ImageComponent
              src={previewImages?.[previewIndex] || "/img.png"}
              loading="lazy"
              alt="Hotel Image"
              width={300}
              height={300}
              objectFit="cover"
            />
          </motion.div>
          <div className="card-icons">
            <span className="icon" onClick={() => setShowShare(true)}>
              <FontAwesomeIcon icon={faUpRightFromSquare} />
            </span>
            <ToggleWishList _id={_id} />
          </div>
          <div className="card-navigation">
            <button onClick={prevImage}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <button onClick={nextImage}>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </div>
        <Link className="card-link" href={`/Hotel/${_id}`}>
          <div className="card-content">
            <h4>{propertyName}</h4>
            <hr />
            <p className="sm">
              {area}, {city}
            </p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Card;
