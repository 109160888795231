import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Context } from "@context/store";

export default function GuestsAmountPicker() {
  const [{ bookingInfo }, dispatch] = useContext(Context);
  const { adults, children, infants, pets } = bookingInfo?.guests;
  const handleIncrease = (type) => {
    dispatch({
      type: "bookingInfo",
      payload: {
        ...bookingInfo,
        guests: { ...bookingInfo.guests, [type]: bookingInfo.guests[type] + 1 },
      },
    });
  };
  const handleDecrease = (type) => {
    if (bookingInfo.guests[type] === 0) return;
    dispatch({
      type: "bookingInfo",
      payload: {
        ...bookingInfo,
        guests: { ...bookingInfo.guests, [type]: bookingInfo.guests[type] - 1 },
      },
    });
  };
  return (
    <div className="guests-amount-picker">
      <div className="modal-body">
        <div className="guest">
          <div className="name">
            <h5>Adults</h5>
            <p>Age 13+</p>
          </div>
          <div className="qty">
            <button onClick={() => handleDecrease("adults")}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <p>{adults}</p>
            <button onClick={() => handleIncrease("adults")}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
        <div className="guest">
          <div className="name">
            <h5>Children</h5>
            <p>Age 2-12</p>
          </div>
          <div className="qty">
            <button onClick={() => handleDecrease("children")}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <p>{children}</p>
            <button onClick={() => handleIncrease("children")}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
        <div className="guest">
          <div className="name">
            <h5>Infants</h5>
            <p>Under 2</p>
          </div>
          <div className="qty">
            <button onClick={() => handleDecrease("Infants")}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <p>{infants}</p>
            <button onClick={() => handleIncrease("Infants")}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
        <div className="guest">
          <div className="name">
            <h5>Pets</h5>
          </div>
          <div className="qty">
            <button onClick={() => handleDecrease("pets")}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <p>{pets}</p>
            <button onClick={() => handleIncrease("pets")}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
