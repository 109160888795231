import CloseIcon from "@icons/general/CloseIcon";
import React, { useRef } from "react";
import { motion } from "framer-motion";
export default function GenericModal({ children, onClose, type = "normal" }) {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose(false);
    }
  };

  let modalVariants;
  if (type == "property") {
    modalVariants = {
      hidden: {
        scale: 0,
        translateY: "-6.5rem",
        translateX: "13rem",
        transformOrigin: "top right",
      },
      visible: {
        scale: 1,
        translateY: "0",
        translateX: "0",
        transition: { duration: 0.5 },
      },
      exit: {
        scale: 0,
        translateY: "-6.5rem",
        translateX: "13rem",
        transformOrigin: "top right",
        transition: { duration: 0.5 },
      },
    };
  } else {
    modalVariants = {
      hidden: {
        scale: 0,
      },
      visible: {
        scale: 1,
        transition: { duration: 0.3 },
      },
      exit: {
        scale: 0,
        transition: { duration: 0.3 },
      },
    };
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.2 } }}
      exit={{ opacity: 0, transition: { delay: 0.5, duration: 0.5 } }}
      className="generic-modal"
      onClick={handleClickOutside}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={modalVariants}
        className="content"
        ref={modalRef}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon onClose={() => onClose(false)} />
        {children}
      </motion.div>
    </motion.div>
  );
}
