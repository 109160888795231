"use client";
import dayjs from "dayjs";
import React, { createContext, useReducer, useEffect } from "react";
import { useAxiosPrivate } from "../api/axios";
const d = dayjs();

const initialState = {
  user: null,
  loader: false,
  selectedType: "",
  bookingInfo: {
    start_date: d.toString(),
    end_date: d.add(1, "month").toString(),
    roomCount: 1,
    amenities: ["all"],
    guests: {
      adults: 1,
      children: 0,
      infants: 0,
      pets: 0,
    },
    location: {
      location: "all",
      city: "",
    },
  },
  prevBookingInfo: {},
  currentProperty: {},
  allProperties: [],
  month_count: 1,
  allPropertyAmenities: [],
  recentlyViewedProperties: [],
  allPropertiesAvailability: [],
  authModalOpened: false,
  formType: "login",
  notifications: [],
  propertyAvailability: {},
  wishList: [],
};

const reducer = (state, action) => ({
  ...state,
  [action.type]: action.payload,
});

export const Context = createContext([initialState, () => null]);

export const Store = ({ children }) => {
  const axios = useAxiosPrivate();
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    const storedState =
      typeof window !== "undefined" && localStorage.getItem("mainState")
        ? JSON.parse(localStorage.getItem("mainState"))
        : initialState;
    return {
      ...storedState,
      loader: false,
      loadingRooms: false,
      user: null,
      allProperties: [],
      allPropertiesAvailability: [],
      allPropertyAmenities: [],
      selectedType: "",
      authModalOpened: false,
      formType: "login",
      notifications: [],
      propertyAvailability: {},
      wishList: [],
      prevBookingInfo: {},
    };
  });

  useEffect(() => {
    localStorage.setItem("mainState", JSON.stringify(state));
  }, [state]);

  const getNotifications = async () => {
    await axios
      .get(`/api/guest/notifications/${state.user._id}`)
      .then((res) => {
        dispatch({
          type: "notifications",
          payload: res.data.data,
        });
      });
  };

  useEffect(() => {
    if (state.user != null) {
      getNotifications();
    }
  }, [state.user]);

  const getWishList = async () => {
    await axios
      .get(`/api/guest/users/getWishList`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("LIH-Token")}`,
        },
      })
      .then((res) => {
        dispatch({
          type: "wishList",
          payload: res.data.data,
        });
      });
  };
  useEffect(() => {
    if (state.user != null) {
      getWishList();
    }
  }, [state.user]);
  const authenticate = (Token) => {
    axios
      .get("/api/guest/users/authenticate", {
        headers: {
          Authorization: `Bearer ${Token.toString()}`,
        },
      })
      .then((response) => {
        dispatch({
          type: "user",
          payload: response.data.user,
        });
      })
      .catch((error) => {
        dispatch({
          type: "user",
          payload: null,
        });
      });
  };

  useEffect(() => {
    const Token = localStorage.getItem("LIH-Token");
    if (Token) {
      authenticate(Token);
    }
  }, []);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};
