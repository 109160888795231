"use client";
import { useAxiosPrivate } from "@/api/axios";
import { Context } from "@/context/store";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

function ToggleWishList({ _id }) {
  const [state, dispatch] = useContext(Context);
  const [wishListed, setWishListed] = useState(false);
  const axios = useAxiosPrivate();
  const toggleWishList = async (id) => {
    setWishListed((prev) => !prev);
    await axios
      .post(
        `/api/guest/users/toggleWishList/${_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("LIH-Token")}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: "wishList",
          payload: res.data.data,
        });
      });
  };

  const handleAddToWishList = () => {
    console.log(state.user);
    if (!state.user) {
      return toast.error("You must be logged in");
    }
    toggleWishList(_id);
  };
  useEffect(() => {
    if (Array.isArray(state?.wishList) && state?.wishList) {
      if (state.wishList?.some((item) => item.property?._id === _id)) {
        setWishListed(true);
      } else {
        setWishListed(false);
      }
    }
  }, [state?.wishList]);
  return (
    <span
      className={`icon ${wishListed && "active"}`}
      onClick={handleAddToWishList}
    >
      <FontAwesomeIcon icon={faHeart} />
    </span>
  );
}

export default ToggleWishList;
