import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const CloseIcon = ({ onClose }) => {
  return (
    <span className="closeIcon" onClick={onClose}>
      <FontAwesomeIcon icon={faClose} />
    </span>
  );
};

export default CloseIcon;
