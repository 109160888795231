"use client";
import { Slider } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import { Context } from "@context/store.jsx";

export default function DurationDatePicker() {
  const [{ bookingInfo }, dispatch] = useContext(Context);
  const [AdapterDayjs, setAdapterDayjs] = useState(null);
  const { start_date, end_date, month_count } = bookingInfo;

  useEffect(() => {
    import("@mui/x-date-pickers/AdapterDayjs").then((module) => {
      setAdapterDayjs(() => module.AdapterDayjs);
    });
  }, []);

  const updateMonthCount = (value) => {
    dispatch({
      type: "bookingInfo",
      payload: {
        ...bookingInfo,
        month_count: value,
        end_date: dayjs(start_date).add(value, "month").toString(), // update end_date based on month count
      },
    });
  };

  const updateStartDate = (value) => {
    if (value) {
      const newStartDate = dayjs(value).toString();
      const newEndDate = dayjs(value)
        .add(month_count ?? 1, "month")
        .toString();
      dispatch({
        type: "bookingInfo",
        payload: {
          ...bookingInfo,
          start_date: newStartDate,
          end_date: newEndDate,
        },
      });
    }
  };

  return (
    <div className="duration-date-picker">
      {AdapterDayjs && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            minDate={dayjs()}
            maxDate={dayjs().add(2, "year")}
            value={dayjs(start_date)}
            onChange={(newValue) => updateStartDate(newValue)}
            slotProps={{
              day: (props) => ({
                ...props,
                sx: {
                  ...(props.selected && {
                    backgroundColor: "#000",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#333",
                    },
                  }),
                },
              }),
            }}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "#000 !important",
                color: "#fff !important",
              },
            }}
          />
        </LocalizationProvider>
      )}
      <h3>
        <span>Check Out: </span>
        {end_date ? dayjs(end_date).format("DD-MM-YYYY") : ""}
      </h3>
      <div className="months-slider">
        <Slider
          value={month_count}
          step={1}
          min={1}
          max={12}
          valueLabelDisplay="on"
          onChange={(e, value) => {
            if (value >= 1) updateMonthCount(value); // Ensure value is at least 1
          }}
          sx={{
            color: "#000000",
            height: 12,
            "& .MuiSlider-thumb": {
              height: 30,
              width: 30,
              backgroundColor: "#fff",
              border: "1px solid #aaaaaa",
              "&:focus, &:hover, &.Mui-active": {
                boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)",
              },
            },
            "& .MuiSlider-valueLabel": {
              backgroundColor: "transparent",
              color: "#000",
              fontWeight: "bold",
              fontSize: 14,
              top: 29,
              "& *": {
                background: "transparent",
              },
            },
            "& .MuiSlider-track": {
              height: 20,
              borderRadius: 10,
              backgroundColor: "#1a1a1a",
            },
            "& .MuiSlider-rail": {
              height: 20,
              borderRadius: 10,
              backgroundColor: "#777e90",
            },
          }}
        />
      </div>
    </div>
  );
}
